.navbar {
  background-color: #fff;
  > a {
    align-items: center;
  }

  .sitename {
    padding-left: 8px;
    font-weight: 600;
  }
}

.table-3 {
  thead tr {
    background-color: lightblue;
  }
}

.avatar-text {
  line-height: 16px;
  margin-left: 0.5rem;
  cursor: pointer;

  .subtitle {
    font-size: 12px;
  }
}

.page-content {
  min-height: 80vh;
}

.AuthPage {
  .card {
    border: none;
    &.card-body {
      box-shadow: 0 30px 27px -5px rgba(50, 50, 93, 0.25),
        0 8px 16px -8px rgba(0, 0, 0, 0.3),
        0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    }
  }
}

.QuestionnairePage {
  .nav-item .nav-link {
    cursor: pointer;
    &.active {
      color: #495057;
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
      background-color: #fff;
    }
  }

  .tab-content {
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    padding: 15px;
    background-color: #fff;
  }

  .wrapper-box {
    position: relative;

    .disabled-box {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1061;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 400 !important;
    line-height: 1;
    color: #fff !important;
    text-shadow: none !important;
    opacity: 1 !important;
  }
}

.StatisticPage {
  .chartjs-render-monitor {
    max-width: 100%;
  }

  .box-wrapper-1 {
    border: 1px solid #e2e2e2;
    padding: 15px 8px;
    border-radius: 5px;

    .inner-box-wrapper-1 {
      background-color: #e8e8e8;
      padding: 5px;
      margin-top: 15px;
    }
  }
}

.WeightScorePage {
  .table thead th {
    vertical-align: middle;
  }

  .scrollable-tb {
    overflow-x: scroll;
  }

  .table tfoot td {
    font-weight: bold;
    border-top: 2px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    background-color: theme-color('secondary');
  }
}

.KpiPage {
  //   .container-wrapper {
  //     overflow-x: scroll;
  //     display: flex;
  //     .inner-grid:first-child {
  //       margin-right: 15px;
  //     }
  //   }
  position: relative;
  .table thead th {
    min-width: 200px;
    text-align: center;
    &:first-child {
      min-width: 150px;
    }
  }
}

.no-sroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.alertPopup {
  position: fixed;
  z-index: 1062;
  height: 2em;
  width: 350px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 2s linear;
    transform: translate(0);
    opacity: 1;
  }

  &.hidden {
    transform: translate(9999px);
    opacity: 0;
  }

  .btn {
    float: right;
  }
}

.loading {
  position: fixed;
  z-index: 1062;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 2s linear;
    transform: translate(0);
    opacity: 1;
  }

  &.hidden {
    transform: translate(9999px);
    opacity: 0;
  }
}

.h-table {
  tr {
    td:first-child {
      font-weight: 700;
    }
  }
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #2879bb !important;
  color: #fff;
  opacity: 1;
}

.disclaimer {
  padding: 0;

  div:first-child {
    margin-bottom: 32px;
    height: 400px;
    overflow-x: visible;
    overflow-y: scroll;
    padding: 15px;
  }
  div:last-child {
    text-align: right;
  }
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      font-size: 14px;
      counter-increment: step-counter;
      margin-bottom: 1rem;
      text-align: justify;
      position: relative;
      padding-left: 25px;
      &::before {
        content: counter(step-counter) '. ';
        position: absolute;
        left: -5px;
        margin-right: 5px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .disclaimer {
    padding: 15px;

    ol {
      li {
        font-size: 1rem;
      }
    }
  }
}

.legend {
  margin-bottom: 15px;
  text-align: center;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding: 0 5px;
      font-size: 12px;
      &::before {
        content: '\25FC';
        font-size: 22px;
        margin-right: 0.5rem;
        vertical-align: middle;
      }

      &:first-child::before {
        color: rgb(69, 182, 73);
        color: rgba(69, 182, 73, 0.3);
      }

      &:nth-child(2)::before {
        color: rgb(40, 121, 187);
        color: rgba(40, 121, 187, 0.3);
      }

      &:nth-child(3)::before {
        color: rgb(255, 215, 0);
        color: rgba(255, 215, 0, 0.3);
      }

      &:nth-child(4)::before {
        color: rgb(49, 61, 76);
        color: rgba(49, 61, 76, 0.3);
      }

      &:last-child::before {
        content: '\2014';
        vertical-align: middle;
        color: red;
      }
    }
  }
}
